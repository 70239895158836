'use client';
// import { Inter } from 'next/font/google';

import { usePathname, useRouter } from 'next/navigation';
import { FC, useEffect, useState } from 'react';

import { SupportProvider } from '../_components/Support/SupportProvider';
import { ToastProvider } from '../_components/Toast/ToastProvider';
import Providers from '../_providers/Providers';

import { useBackendApi } from '@/api';

interface ProtectedLayoutProps {
  children: React.ReactNode;
}

const ProtectedLayout: FC<Readonly<ProtectedLayoutProps>> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const api = useBackendApi();
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await api.authControllerPing();
        router.push('/');
      } catch (e) {
        setAuthCheckComplete(true);
      }
    })();
  }, [router, pathname, api]);

  return (
    authCheckComplete && (
      <Providers providers={[ToastProvider, SupportProvider]}>
        {children}
      </Providers>
    )
  );
};

export default ProtectedLayout;
